import api from "./api";

export default {
    api: 'faqs',
    /**
     * Get all FAQ entries
     * @returns {Promise}
     */
    list(){
        return new Promise((resolve, reject) => {
            api().get(this.api).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Get a FAQ Entry by id
     * @param {string|number} id FAQ ID
     * @returns {Promise}
     */
    get(id){
        return new Promise((resolve, reject) => {
            api().get(this.api + '/' + id).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Create new FAQ entry
     * @param {object} data FAQ Object with all attr
     * @returns {Promise}
     */
    add(data) {
        return new Promise((resolve, reject) => {
            api().post(this.api, data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Update FAQ object by id
     * @param {object} data FAQ object with all attr, includes the id
     * @returns {Promise}
     */
    edit(data){
        return new Promise((resolve, reject) => {
            api().put(this.api + '/' + data.id, data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Delete one or more FAQs by id
     * @param {array} data Array of FAQ ids to delete
     * @returns {Promise}
     */
    delete(data){
        return new Promise((resolve, reject) => {
            api().delete(this.api, { data: data}).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
};
